import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userName, setUserName] = useState(null);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');
        const name = localStorage.getItem('name');

        if (token && role && name) {
            setIsAuthenticated(true);
            setUserRole(role);
            setUserName(name);
        } else {
            setIsAuthenticated(false);
            setUserRole(null);
            setUserName(null);
        }
    }, []);


    const refreshAuth = () => {
        const token = localStorage.getItem('token');
        const name = localStorage.getItem('name');
        const role = localStorage.getItem('role');

        if (token && role) {
            setIsAuthenticated(true);
            setUserName(name);
            setUserRole(role);
        } else {
            setIsAuthenticated(false);
            setUserName(null);
            setUserRole(null);
        }
    };

    const login = (token, name, role) => {
        localStorage.setItem('token', token);
        localStorage.setItem('name', name);
        localStorage.setItem('role', role);
        setIsAuthenticated(true);
        setUserName(name);
        setUserRole(role);
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('role');
        setIsAuthenticated(false);
        setUserName(null);
        setUserRole(null);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, userName, userRole, login, logout, refreshAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

