import React from "react";

export default function Space() {
    return (
        <>
            <div className="min-h-[100px] bg-green-500">

            </div>
        </>
    )
}